const index = () => import("./components/index.vue");
const Receipt = () => import("./components/receipt.vue");
const viewReceipt = () => import("./components/viewReceipt.vue");
const alumniRegistration = () => import("./components/alumniRegistration.vue");

export const routes = [
  {
    path: "/",
    name: "chooseevent",
    component: index,
  },
  {
    path: "/receipt",
    name: "receipt",
    component: Receipt,
  },
  {
    path: "/error-receipt",
    name: "viewReceipt",
    component: viewReceipt,
  },
  {
    path: "/alumni-registration",
    name: "alumniRegistration",
    component: alumniRegistration,
  },
];
export default routes;
